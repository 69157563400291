/*
 * @Author: 林娴
 * @LastEditors: 林娴
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.eventName = ''
      this.ticketSalesStartTimeBegins = []
      this.ticketSalesStartTime = ''
      this.ticketSalesEndTime = ''
      this.ticketSalesEndTimeBegins = []
      this.ticketSalesEndTimeBegin = ''
      this.ticketSalesEndTimeEnd = ''
      this.eventType = ''
      this.publishStatus = ''
      this.top = ''
    }
  }
}
export default searchFrom
