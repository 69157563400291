/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-12-15 10:16:24
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePrice = (rule, value, callback) => {
    // if (!value) {
    //   callback([new Error('请输入参加费用')]);
    // } else {
        if (Number(value) < 0) {
          callback([new Error('参加费用不能为负数')]);
        } else {
            callback();
        }
  //  }
  }
  constructor(type) {
    if (type === 'form') {
      this.status = 'first'
      this.eventName = ''
      this.detail = ''
      this.registerCodes = ['name', 'mobile']
      this.introduction = ''
      this.price = ''
      this.maxQty = ''
      this.ticketSalesStartTime = ''
      this.ticketSalesEndTime = ''
      this.performanceStartTime = ''
      this.picturePath = ''
      this.pictureUrl = ''
      this.files = []
      this.groups = []
      this.zubie = ''
      this.ticketIds = []
      this.singleTicketLimit = ''
      this.eventType =''
      this.performanceAddress = ''
      this.time = ''
      this.performanceLength = ''
      this.eventThemeCode = ''
      // 无需传给后端
      this.filesPicturesPath = []
      this.returnFlag = ''
    } else if (type === 'rule') {
      this.returnFlag = [{
        required: true,
        message: '请选择',
        trigger: 'change',
      },]
      this.shoujia = [
        {
          required: true,
          message: '请输价格',
          trigger: 'change',
        },
      ]
      this.groups  = [
        {
          required: true,
          message: '请输入价格',
          trigger: 'change',
        },
      ]
      this.registerCodes = [
        {
          required: true,
          message: '请选择售票信息',
          trigger: 'change',
        },
      ]
      this.eventName = [
        {
          required: true,
          message: '请输入活动名称',
          trigger: 'change',
        },
      ]
      this.eventType = [
        {
          required: true,
          message: '请选择活动类型',
          trigger: 'change',
        },
      ]
      this.duration = [
        {
          required: true,
          message: '请输入时长',
          trigger: 'change',
        },
      ]
      this.introduction = [
        {
          required: true,
          message: '请输入活动简介',
          trigger: 'change',
        },
      ]
      this.detail = [
        {
          required: true,
          message: '请输入活动详情介绍',
          trigger: 'change',
        },
      ]
      this.price = [
        {
          required: true,
          validator: this.validatePrice,
          trigger: 'change',
        },
      ]
      this.maxQty = [
        {
          required: true,
          message: '请输入活动人数上限',
          trigger: 'change',
        },
      ]
      this.courseQty = [
        {
          required: true,
          message: '请输入活动节数',
          trigger: 'change',
        },
      ]
      this.coach = [
        {
          required: true,
          message: '请输入教练',
          trigger: 'change',
        },
      ]
      this.ticketSalesStartTime = [
        {
          required: true,
          message: '请输入选择售票开始时间',
          trigger: 'change',
        },
      ]
      this.ticketSalesEndTime = [
        {
          required: true,
          message: '请输入选择售票截止时间',
          trigger: 'change',
        },
      ]
      this.performanceStartTime = [
        {
          required: true,
          message: '请输入选择活动开始时间',
          trigger: 'change',
        },
      ]
      this.performanceEndTime = [
        {
          required: true,
          message: '请输入选择活动结束时间',
          trigger: 'change',
        },
      ]
      this.picturePath = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
      this.rules = [
        {
          required: true,
          message: '请选择活动制度',
          trigger: 'change',
        },
      ]
      this.singleTicketLimit = [
        {
          required: true,
          message: '请输入单次购票数量',
          trigger: 'change',
        },
      ]
      this.maxQty = [
        {
          required: true,
          message: '请输入票数上限',
          trigger: 'change',
        },
      ]
      this.performanceAddress = [
        {
          required: true,
          message: '请输入活动地址',
          trigger: 'change',
        },
      ]
      this.performanceLength = [
        {
          required: true,
          message: '请输入活动时长',
          trigger: 'change',
        },
      ]
      this.eventThemeCode = [
        {
          required: true,
          message: '请输入活动主题',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
